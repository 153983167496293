<template>
    <div :key="reloadKey">
        <div class="flex items-center flex-col px-5 md:px-0">
            <p v-if="this.id == 'districts_content_2'"  class="md:text-sectionTitle text-xl w-full font-understock">Discover More</p>
            <p v-else class="md:text-sectionTitle text-xl w-full text-center font-understock">{{ $t('districts.title') }}</p>
        </div>
        <div v-for="district of districts" :key="district.id" class="relative flex overflow-hidden rounded-md m-4">
            
                <!-- begin::image -->
                <transition mode="out-in" name="slide-image2">

                    <img v-if="district.id == 9998 || district.id == 9999" class="h-full w-full object-cover absolute md:block" :key="district.id" 
                        :src="district.cover"
                        :lazy-src="district.cover"
                        alt="city">

                    <img v-else class="h-full w-full object-cover absolute md:block" :key="district.id" 
                        :src="$mediaFile('districts', district.id , district.cover)"
                        :lazy-src="$mediaFile('districts', district.id , district.cover)"
                        alt="city">
                        
                </transition>
                <!-- begin::end image -->
                

                <!-- begin::overlay -->
                <transition name="fade">
                    <div v-if="show" class="overlay-districts h-full w-full md:block relative">
                        <div class="py-3 px-3 h-full w-full relative">
                            <p v-if="district.name!='Vineyards' && district.name!='Beachfront'" class="uppercase leading-none font-blackMango font-black text-white text-lg tracking-wide z-40 relative mb-2 mt-4">
                            {{ $t('districts.ThisIsTitle') + " " + district.name }}</p>
                            <p v-else class="uppercase leading-none font-blackMango font-black text-white text-lg tracking-wide z-40 relative mb-2 mt-4">
                            {{ district.name }}</p>
                            <div class="flex">
                                <button @click="listCity(district.name)" class="hover:bg-darkBronze bg-bronze z-40 font-medium uppercase text-xs tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-1/2 md:w-64 py-1 md:py-3">
                                    <span>{{ $t('buttons.listings') }}</span>
                                    <div class="absolute right-3 flex justify-center">
                                    </div>
                                </button>
                                <button @click="generateLink(district.id)" class="z-40 font-medium uppercase text-xs tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-1/2 md:w-64 py-1 md:py-3">
                                    <span>{{ $t('buttons.discover') }}</span>
                                    <div class="absolute right-3 flex justify-center">
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- end::overlay-->

                <!-- begin:: districts text -->
                <transition mode="out-in" name="slow-slide-fade"> 
                    
                </transition>
                <!-- end:: districts text -->
        
        </div>
        <div v-if="id=='districts_content'" class="flex relative justify-center">
            <button v-if="showMoreFlag" class="uppercase tracking-button text-sm font-medium inline-flex items-center justify-center relative" @click="showMore()">{{$t('advancedFilters.showMore')}}</button>
            <button v-else class="uppercase tracking-button text-sm font-medium inline-flex items-center justify-center relative" @click="showLess()">{{$t('advancedFilters.showLess')}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DistrictsMobile",
        components: {
        },
        props: ['items', 'id'],
        data() {
            return {
                show: true,
                disctricts: [],
                reloadKey: 0,
                showMoreFlag: true,
            }
        },
        created() {
            if(this.id == "districts_content") this.districts = this.items.slice(0,3);
            else this.districts = this.items;
        },
        methods:{
            generateLink(id){
                this.$router.push({ name: 'districts', params: { lang: this.$i18n.locale, slug: id }}).catch(() => {})
                window.location.reload();
            },
            generateLink2(id){
                this.$router.push({ name: 'districts', hash: '#listings_content', params: { lang: this.$i18n.locale, slug: id }})
            },
            listCity(city){
                let myLinkData = "city:" + city
                this.$emit('city-changed', city)
                this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
            },
            showMore(){
                this.districts = this.items;
                this.showMoreFlag = false;
                this.reloadKey++;
            },
            showLess(){
                this.districts = this.items.slice(0,3);
                this.showMoreFlag = true;
                this.reloadKey++;
            }
        }
        
    }
</script>


